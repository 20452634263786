<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <span class="a-fs-16 a-ptb-6 a-fw-b">消息中心</span>
            <div class="a-flex-rfec">
                <el-checkbox v-model="checked" class="checkedBox"
                    >仅显示未读</el-checkbox
                >
                <el-button class="a-ml-32" @click="allReadyRead"
                    >标记为已读</el-button
                >
            </div>
        </div>

        <div class="todolist a-flex-rsbfs a-fs-14">
            <el-card class="todolist-left a-flex-cfsfs">
                <div
                    class="todolist-left-item a-cursor-p"
                    @click="pageParam.params.notifyType = item.keyType"
                    :class="{
                        active: pageParam.params.notifyType == item.keyType,
                    }"
                    v-for="(item, index) in newsnum.newslist"
                    :key="index"
                >
                    {{ item.keyType }} ({{ item.notReadNum }})
                </div>
            </el-card>
            <el-card class="todolist-right a-ml-16 el-main">
                <le-search-form>
                    <le-input-icon-search
                        label="消息内容"
                        v-model="pageParam.params.content"
                        placeholder="请输入"
                    />
                    <le-date-range
                        label="通知时间"
                        :minDate.sync="pageParam.params.startTime"
                        :maxDate.sync="pageParam.params.endTime"
                        style="margin-left: 64px"
                    />
                </le-search-form>

                <le-pagview @setData="setTableData" :pageParam="pageParam">
                    <el-table
                        :data="tableData"
                        :row-class-name="tableRowClassName"
                        @selection-change="handleSelectionChange"
                        :highlight-current-row="true"
                        style="width: 100%"
                    >
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column label="内容" width="800">
                            <template slot-scope="{ row }">
                                <span
                                    class="readRound"
                                    v-if="!row.isRead"
                                ></span>
                                <span
                                    class="a-ml-15"
                                    @click="handlerGoDetail(row)"
                                    :class="
                                        row.pcNotifyPage
                                            ? 'a-cursor-p'
                                            : 'a-default-p'
                                    "
                                    >{{ row.content }}</span
                                >
                            </template>
                        </el-table-column>
                        <el-table-column prop="userName" label="操作人">
                        </el-table-column>
                        <el-table-column
                            prop="createTimeText"
                            label="通知时间"
                            width="200"
                            class="a-c-second"
                        >
                        </el-table-column>
                    </el-table>
                </le-pagview>
            </el-card>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
    data() {
        return {
            pageParam: {
                url: this.$Config.apiUrl.getNotifyList,
                method: "post",
                params: {
                    notifyType: "",
                    exportFlag: false,
                    readFlag: "",
                    content: "",
                    startTime: "",
                    endTime: "",
                },
                freshCtrl: 1,
            },
            tableData: [],
            multipleSelection: [],
            checked: false,
        };
    },
    created() {},
    computed: {
        ...mapState({
            newsnum: (state) => state.newsnum.newsnum,
        }),
    },
    watch: {
        "pageParam.params": {
            handler(val) {
                this.pageParam.freshCtrl++;
            },
            deep: true,
        },
        checked(val) {
            this.pageParam.params.readFlag = val ? 0 : "";
            this.pageParam.freshCtrl++;
        },
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {},
        setTableData(data) {
            this.tableData = data;
        },
        handlerSearch() {
            this.pageParam.freshCtrl++;
        },
        handlerGoDetail() {},
        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(val);
            if (val.length) {
                val.map((item, index) => {});
            }
        },
        handlerGoDetail(obj) {
            if (obj.pcNotifyPage && obj.pcNotifyPageParam) {
                this.allReadyRead(obj);
                this.$router.push({
                    path: obj.pcNotifyPage,
                    query: JSON.parse(obj.pcNotifyPageParam),
                });
            }
        },
        allReadyRead(item) {
            let arry = [];
            if (!!item) {
                arry.push(item.id);
            } else {
                if (!this.multipleSelection.length) {
                    return false;
                } else {
                    this.multipleSelection.map((item, index) => {
                        arry.push(item.id);
                    });
                }
            }

            this.$Axios
                ._get({
                    url: this.$Config.apiUrl.readNotify,
                    params: { notifyIds: arry },
                })
                .then((res) => {
                    if (res.result.code == 0) {
                        this.pageParam.freshCtrl++;
                        this.$store.dispatch("newsnum/getNewsNum");
                    } else {
                        this.$message.error(res.result.msg);
                    }
                })
                .catch((error) => {
                    this.$message.error(error);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.todolist {
    height: 100%;
    // overflow: auto;

    &-left {
        height: calc(100% - 80px);

        /deep/ .el-card__body {
            padding: 0;
            width: 100%;
            padding-bottom: 50px;
        }

        width: 184px;
        padding: 16px 8px;

        &-item {
            height: 40px;
            line-height: 40px;
            padding-left: 16px;
            border-radius: 4px;
            width: 100%;

            &:hover {
                font-weight: bold;
                // background: #EBF0F5;
            }

            &.active {
                font-weight: bold;
                background: #ebf0f5;
            }
        }
    }

    &-right {
        height: calc(100% - 80px);
    }
}

.checkedBox /deep/ .el-checkbox__inner {
    border-radius: 100%;
}

.readRound {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: #ff3b30;
    display: inline-block;
}

/deep/ .searchToo {
    display: none;
}
</style>
